<template>
  <div class="contacts">
    <MainComponent sectionName="contacts" />

    <div class="container-fluid">
      <section>
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-5"
          >
            <h1 class="my-auto text-size-32 fw-bold text-center text-size-32">
              CONTACTANOS
            </h1>
          </div>
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10"
          >
            <p class="text-size-28 mt-3 text-center">
              En Plomeros911, valoramos la comunicación directa y estamos aquí
              para atender tus consultas, resolver tus problemas de fontanería y
              brindarte la asistencia que necesitas. Nuestro equipo está listo
              para responder tus preguntas, programar servicios y proporcionarte
              la información que buscas. Ya sea que necesites una reparación
              urgente, solicitar un presupuesto o simplemente obtener
              orientación, estamos a tu disposición.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div class="row my-7 justify-content-center">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
          >
            <h3 class="mb-5 text-size-32 fw-bold text-center text-size-32">
              COMPLETE EL FORMULARIO PARA CONTACTARNOS
            </h3>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div class="form-target p-5 rounded-4 position-relative">
              <form ref="form" @submit.prevent="sendEmail">
                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Nombre y Apellido</label
                  >
                  <input
                    type="text"
                    name="from_name"
                    class="form-control"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Email</label
                  >
                  <input
                    type="email"
                    name="email_user"
                    class="form-control"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Telefono</label
                  >
                  <input
                    type="number"
                    name="tel_user"
                    class="form-control"
                    required
                    pattern="[0-9]"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Mensaje</label
                  >
                  <textarea
                    name="message"
                    rows="5"
                    required
                    class="form-control"
                  ></textarea>
                </div>
                <input
                  type="text"
                  hidden
                  name="to_email"
                  value="support@plomeros911.pro"
                />
                <input
                  type="text"
                  hidden
                  name="domain"
                  value="plomeros911.pro"
                />
                <button
                  type="submit"
                  class="btn btn-easy-green text-size-20 w-100 mt-4"
                >
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import MainComponent from "@/components/Main.vue";
import emailjs from "@emailjs/browser";
export default {
  name: "ContactsView",
  components: {
    MainComponent,
  },
  methods: {
    sendEmail() {
      this.$swal({
        title: "Enviando correo..",
        icon: "info",
        text: "",
        showConfirmButton: false,
        showCloseButton: false,
      });
      emailjs
        .sendForm(
          process.env.VUE_APP_EMAILJS_SERVER,
          process.env.VUE_APP_EMAILJS_TEMPLATE_CONTACT,
          this.$refs.form,
          process.env.VUE_APP_EMAILJS_API
        )
        .then(
          (result) => {
            if (result.status == 200) {
              this.$swal({
                title: "Correo enviado",
                icon: "success",
                text: "Mensaje enviado",
              });
              this.$refs.form.reset();
            }
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.$swal({
              title: "Correo no enviado",
              icon: "error",
              text: "Hubo un error de envio",
            });
          }
        );
    },
  },
};
</script>
