<template>
  <div class="gallery">
    <MainComponent sectionName="gallery" />

    <div class="container-fluid">
      <section>
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-5"
          >
            <h1 class="my-auto text-size-32 fw-bold text-center text-size-32">
              GALERIA DE TRABAJO REALIZADOS
            </h1>
          </div>
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10"
          >
            <p class="text-size-28 mt-3 text-center">
              ¡Bienvenido a nuestra Galería Plomeros911, donde te invitamos a
              explorar nuestro dedicado trabajo en el mundo de la fontanería!
              Aquí, compartimos con orgullo momentos capturados de nuestros
              proyectos, desde reparaciones rápidas hasta instalaciones
              detalladas. Sumérgete en nuestra galería para obtener una visión
              visual de la calidad y el profesionalismo que Plomeros911 ofrece
              en cada servicio.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div class="my-5">
          <div class="row">
            <div
              class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 my-4 text-center"
              v-for="i in 8"
              :key="i"
            >
              <button class="btn bg-color_2 p-2" @click="openVideo(i)">
                <div class="thumbs-wrapper animation-scale-in-out">
                  <img
                    :src="require(`../assets/media/thumbs/video_${i}.png`)"
                    alt="#"
                    class="w-100 h-100 rounded"
                  />
                </div>
              </button>
              <FsLightbox
                :toggler="toggleVideo(i)"
                :sources="[require(`../assets/media/video_${i}.mp4`)]"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import MainComponent from "@/components/Main.vue";
import FsLightbox from "fslightbox-vue/v3";
export default {
  name: "GalleryView",
  components: {
    MainComponent,
    FsLightbox,
  },
  data() {
    return {
      video_1: false,
      video_2: false,
      video_3: false,
      video_4: false,
      video_5: false,
      video_6: false,
      video_7: false,
      video_8: false,
    };
  },
  methods: {
    openVideo(video) {
      if (video == 1) {
        this.video_1 = !this.video_1;
      }
      if (video == 2) {
        this.video_2 = !this.video_2;
      }
      if (video == 3) {
        this.video_3 = !this.video_3;
      }
      if (video == 4) {
        this.video_4 = !this.video_4;
      }

      if (video == 5) {
        this.video_5 = !this.video_5;
      }
      if (video == 6) {
        this.video_6 = !this.video_6;
      }
      if (video == 7) {
        this.video_7 = !this.video_7;
      }
      if (video == 8) {
        this.video_8 = !this.video_8;
      }
    },
    toggleVideo(video) {
      if (video == 1) {
        return this.video_1;
      }
      if (video == 2) {
        return this.video_2;
      }
      if (video == 3) {
        return this.video_3;
      }
      if (video == 4) {
        return this.video_4;
      }
      if (video == 5) {
        return this.video_5;
      }
      if (video == 6) {
        return this.video_6;
      }
      if (video == 7) {
        return this.video_7;
      }
      if (video == 8) {
        return this.video_8;
      }
    },
  },
};
</script>
