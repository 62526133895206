<script setup>
const getYear = () => {
  return new Date().getFullYear();
};
const setYear = getYear();

const socialNetworks = [
  {
    name: "instagram",
    url: "https://www.instagram.com/plomeros911/",
    icon: "fab fa-instagram",
  },
  {
    name: "whatsapp",
    url: "https://wa.me/message/DBF2PLBXZCMQA1",
    icon: "fab fa-whatsapp",
  },
  {
    name: "tiktok",
    url: "https://www.tiktok.com/@plomeros911",
    icon: "fab fa-tiktok",
  },
  {
    name: "facebook",
    url: "#",
    icon: "fab fa-facebook",
  },
  {
    name: "youtube",
    url: "https://www.youtube.com/channel/UCQ8l3NI9OieAANaSVrQHkTA",
    icon: "fab fa-youtube",
  },
];
</script>

<template>
  <footer>
    <div class="container-fluid bg-blackblue">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 py-4">
          <div class="d-flex justify-content-center align-items-center h-100">
            <img
              alt="plomeros911 logo"
              class="w-50 d-block mx-auto"
              src="../assets/logo_white.png"
            />
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 py-4">
          <div>
            <h3 class="fw-bold text-size-30 text-white">Navegación</h3>
          </div>
          <div>
            <ul class="nav flex-column">
              <li class="nav-item">
                <router-link
                  to="/"
                  class="nav-link text-size-20 text-white ps-0 d-inline-block"
                  >Home</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/acerca-de-nosotros"
                  class="nav-link text-size-20 text-white ps-0 d-inline-block"
                  >Acerca de Nosotros</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/galeria"
                  class="nav-link text-size-20 text-white ps-0 d-inline-block"
                  >Galeria</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/oportunidades"
                  class="nav-link text-size-20 text-white ps-0 d-inline-block"
                  >Oportunidades Laborales</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/contacto"
                  class="nav-link text-size-20 text-white ps-0 d-inline-block"
                  >Contacto</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 py-4">
          <div>
            <h3 class="fw-bold text-size-30 text-white">Encuentranos</h3>
          </div>
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5547.949817925216!2d-66.88073830480273!3d10.501221817828407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a59179ea13d53%3A0x5c77ceba46d1f757!2sLa%20Florida%2C%20Caracas%2C%20Distrito%20Capital%2C%20Venezuela!5e0!3m2!1ses!2sdo!4v1730391471068!5m2!1ses!2sdo"
              class="w-100"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 py-4">
          <div>
            <h3 class="fw-bold text-size-30 text-white">Contacto</h3>
          </div>
          <div>
            <ul class="nav flex-column">
              <li>
                <p class="text-white">
                  <i class="fas fa-map-marker-alt pe-2"></i>
                  Dirección: Urb. La Florida, Calle Las Palmas, Quinta Lamp, Caracas, Distrito Capital. Venezuela
                </p>
              </li>
              <li>
                <p class="text-white">
                  <i class="fas fa-phone-alt pe-2"></i>
                  Teléfonos:
                  <a
                    href="https://api.whatsapp.com/send?phone=584241925142"
                    class="text-white"
                    >+584241925142</a
                  >
                  /
                  <a
                    href="https://api.whatsapp.com/send?phone=584120276995"
                    class="text-white"
                    >+584120276995</a
                  >
                </p>
              </li>
              <li>
                <p class="text-white">
                  <i class="fas fa-envelope pe-2"></i>
                  Correo: support@plomeros911.pro
                </p>
              </li>
              <li>
                <p class="text-white">
                  <i class="fas fa-headset pe-2"></i>
                  Horario Atencion en linea: Lunes a Domingo de 7:00 a.m. – 8:00 p.m
                </p>
                <p class="text-white">
                  <i class="fas fa-building pe-2"></i>
                  Horario de Trabajo: Lunes a Sábado de 8:00 a.m. – 6:00 p.m
                </p>
              </li>
              <li>
                <div class="d-flex">
                  <div
                    v-for="(item, index) in socialNetworks"
                    :key="index"
                    class="pe-3"
                  >
                    <a :href="item.url" class="text-white d-block"
                      ><i :class="item.icon + ' icons-brands-size'"></i
                    ></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row border-top border-4 border-white">
        <div class="col-12 ol-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          <p class="text-white m-0 py-3 text-center">
            Copyright © {{ setYear }} plomeros911.pro
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterComponent",
};
</script>
