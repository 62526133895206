<template>
  <div class="opportunities">
    <MainComponent sectionName="opportunities" />

    <div class="container-fluid">
      <section>
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-5"
          >
            <h1 class="my-auto text-size-32 fw-bold text-center text-size-32">
              OPORTUNIDADES LABORALES
            </h1>
          </div>
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10"
          >
            <p class="text-size-28 mt-3 text-center">
              ¡Explora las Oportunidades de Trabajo en Plomeros911! Nos
              mantenemos en la búsqueda constante de talento que tenga interés
              en participar de forma comprometida, ofreciendo y respaldando las
              mejores soluciones tecnológicas para la industria de la plomería.
              Si valoras la excelencia en el servicio al cliente y estás listo
              para unirte a un equipo dinámico, has llegado al lugar correcto.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div class="row my-7 justify-content-center">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
          >
            <h3 class="mb-5 text-size-32 fw-bold text-center text-size-32">
              FORMULARIO PARA POSTULANTES
            </h3>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div class="form-target p-5 rounded-4">
              <form
                ref="form"
                @submit.prevent="sendEmail"
                enctype="multipart/form-data"
              >
                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Nombre y Apellido</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="employer_name"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Número de cédula</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    required
                    name="employer_dni"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Adjunte foto de su cédula</label
                  >
                  <input
                    type="file"
                    class="form-control"
                    name="employer_dni_file"
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Numero de teléfono (preferible que el numero posea
                    WhatsApp)
                  </label>
                  <input
                    type="text"
                    name="employer_phone"
                    class="form-control"
                    required
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Correo electronico
                  </label>
                  <input
                    type="email"
                    name="employer_email"
                    class="form-control"
                    required
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Seleccione su nivel de estudios
                  </label>
                  <select name="employer_studies" required class="form-control">
                    <option value="Bachiller">Bachiller</option>
                    <option value="Carrera tecnica maestria">
                      Carrera técnica - titulado
                    </option>
                    <option value="Carrera técnica maestría">
                      Carrera técnica - maestría
                    </option>
                  </select>
                </div>

                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Indique cuantos años de experiencia posee como
                    plomero</label
                  >
                  <select
                    name="employer_experience"
                    required
                    class="form-control"
                  >
                    <option value="Sin experiencia">Sin experiencia</option>
                    <option value="Menos de un año">Menos de un año</option>
                    <option value="Mas de un año">Mas de un año</option>
                    <option value="Mas de dos años">Mas de dos años</option>
                    <option value="Mas de tres años">Mas de tres años</option>
                    <option value="Mas de cinco años">Mas de cinco años</option>
                    <option value="Mas de diez años">Mas de diez años</option>
                    <option value="Mas de quince años">
                      Mas de quince años
                    </option>
                  </select>
                </div>

                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Seleccione el medio de transporte que posee</label
                  >
                  <select
                    name="employer_transport"
                    class="form-control"
                    required
                  >
                    <option value="carro">Carro</option>
                    <option value="moto">Moto</option>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Describa que tipo herramientas posee</label
                  >
                  <textarea
                    name="employer_description_kittools"
                    rows="5"
                    class="form-control"
                  ></textarea>
                </div>

                <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Adjunte las fotos de las herramientas previamente descrita
                    (si no posee, deje el campo vacío)</label
                  >
                  <input
                    type="file"
                    name="employer_kittools_file"
                    class="form-control"
                    multiple
                  />
                </div>

                <!-- <div class="mb-3">
                  <label class="form-label text-white text-size-20"
                    >Adjunte fotos tipo carnet
                  </label>
                  <input
                    type="file"
                    name="employer_photo_file"
                    class="form-control"
                    multiple
                  />
                </div> -->

                <input
                  type="text"
                  hidden
                  name="to_email"
                  value="support@plomeros911.pro"
                />
                <input
                  type="text"
                  hidden
                  name="domain"
                  value="plomeros911.pro"
                />
                <input type="text" hidden name="host" value="plomeros911" />

                <button
                  type="submit"
                  class="btn btn-easy-green text-size-20 w-100 mt-4"
                >
                  Enviar solicitud
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import MainComponent from "@/components/Main.vue";
import axios from "axios";
import emailjs from "@emailjs/browser";
export default {
  name: "OpportunitiesView",
  components: {
    MainComponent,
  },
  data() {
    return {
      testFile: [],
    };
  },
  methods: {
    async sendEmail() {
      this.$swal({
        title: "Subiendo imagenes..",
        icon: "info",
        text: "por favor espere...",
        showConfirmButton: false,
        showCloseButton: false,
      });

      //SEND FILES
      const apiKey = "8db999d020eb6b3fc6726b245a0daff7";
      const formData = new FormData();

      let getFileDni = document.querySelector(
        'input[name="employer_dni_file"]'
      ).files;

      let getFileKits = document.querySelector(
        'input[name="employer_kittools_file"]'
      ).files;

      /*  let getFilePhoto = document.querySelector(
        'input[name="employer_photo_file"]'
      ).files; */

      await uploadImages(getFileDni, this.$refs.form.employer_name.value);
      await uploadImages(getFileKits, this.$refs.form.employer_name.value);
      //await uploadImages(getFilePhoto, this.$refs.form.employee_name.value);

      this.$swal({
        title: "Enviando correo..",
        icon: "info",
        text: "",
        showConfirmButton: false,
        showCloseButton: false,
      });

      //FILE KITS
      async function uploadImages(searchFiles, nameFile) {
        for (const file of searchFiles) {
          formData.append("image", file);
          formData.append("name", nameFile.replace(" ", "_"));
          try {
            const response = await axios.post(
              `https://api.imgbb.com/1/upload?key=${apiKey}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            console.log("imagen status:", response.data.status);
          } catch (error) {
            console.error("Error al subir la imagen:", error);
          }
        }
      }

      const emailParams = {
        employer_name: this.$refs.form.employer_name.value,
        employer_dni: this.$refs.form.employer_dni.value,
        employer_email: this.$refs.form.employer_email.value,
        employer_studies: this.$refs.form.employer_studies.value,
        employer_transport: this.$refs.form.employer_transport.value,
        employer_experience: this.$refs.form.employer_experience.value,
        employer_description_kittools:
          this.$refs.form.employer_description_kittools.value,
        employer_phone: this.$refs.form.employer_phone.value,
        domain: this.$refs.form.domain.value,
        host: this.$refs.form.host.value,
        to_email: this.$refs.form.to_email.value,
      };

      console.log(emailParams);

      try {
        const result = await emailjs.send(
          process.env.VUE_APP_EMAILJS_SERVER,
          process.env.VUE_APP_EMAILJS_TEMPLATE_OPPORTUNITIES,
          emailParams,
          process.env.VUE_APP_EMAILJS_API
        );

        if (result.status == 200) {
          this.$swal({
            title: "Correo enviado",
            icon: "success",
            text: "Mensaje enviado",
          });
          this.$refs.form.reset();
        }
      } catch (error) {
        console.log("FAILED...", error.text);
        this.$swal({
          title: "Correo no enviado",
          icon: "error",
          text: "Hubo un error de envio",
        });
      }
    },
  },
};
</script>
