<template>
  <div>
    <HeaderComponent />
    <main>
      <router-view />
    </main>
    <FooterComponent />
    <ButtonFlotingComponent />
  </div>
</template>

<script>
import HeaderComponent from "@/components/Header.vue";
import FooterComponent from "@/components/Footer.vue";
import ButtonFlotingComponent from "@/components/ButtonFloting.vue";
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    ButtonFlotingComponent,
  },
};
</script>
