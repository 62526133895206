<script setup>
const valuesAbout = [
  {
    name: "Trabajo en equipo",
    description: "juntos somos mas",
  },
  {
    name: "Servicio",
    description: "El cliente primero",
  },
  {
    name: "Lealtad",
    description: "Confianza y union de equipo",
  },
  {
    name: "Organizacion",
    description: "Orden para lograr resultados",
  },
  {
    name: "Comunicacion formail",
    description: "Confiabilidad en el hacer",
  },
  {
    name: "Respeto",
    description: "Nuestra base de entendimiento",
  },
  {
    name: "Profesionalismo",
    description: "comprometido con el trabajo",
  },
  {
    name: "Integridad",
    description: "Hacemos lo que decimos",
  },
];
</script>
<template>
  <div class="about">
    <MainComponent sectionName="about" />

    <div class="container-fluid">
      <section>
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-5"
          >
            <h1 class="my-auto text-size-32 fw-bold text-center text-size-32">
              ACERCA DE NOSOTROS
            </h1>
          </div>
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10"
          >
            <p class="text-size-28 mt-3 text-center">
              Plomeros911 constituye en la actualidad una empresa que tiene por
              objeto de la prestación de servicios de plomería a domicilio en el
              Distrito Capital, Venezuela.
            </p>
            <p class="text-size-28 text-center">
              Somos un equipo multidisciplinario conformado por gente entusiasta
              que disfruta de estar involucrado en las nuevas tecnologías, gusta
              de los retos y se compromete con entregar soluciones a los
              problemas de nuestros clientes, que otorguen beneficios y
              resultados tangibles adaptados a sus necesidades.
            </p>
            <p class="text-size-28 text-center">
              En Plomeros 911 desarrollamos relaciones de confianza y de largo
              plazo, colaborando con nuestros clientes para satisfacer sus
              requerimientos en el área de plomería.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div class="my-7">
          <div class="row justify-content-center">
            <div
              class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 fix-about-image"
            >
              <div
                class="d-flex justify-content-center align-items-center h-100"
              >
                <img
                  src="../assets/images/about/image_1.jpg"
                  alt="Plomeros 911 mision"
                  class="w-100 rounded-4"
                />
              </div>
            </div>
            <div
              class="offset-xl-1 offset-xxl-1 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 my-auto"
            >
              <h3 class="text-size-32 fw-bold text-center text-size-32">
                MISION
              </h3>
              <p class="text-size-26 mt-4 text-center">
                Ofrecer servicios de plomería de alta calidad que logre un
                impacto positivo a largo plazo en nuestros clientes. Trabajar
                para el crecimiento de nuestro negocio con valores e integridad.
                Contribuir con el desarrollo de nuestros empleados y fomentar en
                ellos sentido de pertenencia.
              </p>
              <p class="text-size-26 mt-4 text-center">
                Nuestro desafió: Inspirarnos, Innovar y ajustarnos siempre a las
                más avanzadas tecnologías y normas de calidad
              </p>
            </div>
          </div>

          <div class="row justify-content-center mt-10">
            <div
              class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 my-auto"
            >
              <h3 class="text-size-32 fw-bold text-center text-size-32">
                VISION
              </h3>
              <p class="text-size-28 mt-4 text-center">
                Posicionarnos como una empresa líder en la prestación de
                servicios enfocados en la plomería industrial empleando
                tecnología de punta, caracterizándonos por nuestro sello
                distintivo de gran calidad y por la confianza y garantía que
                otorgamos a nuestros clientes.
              </p>
            </div>
            <div
              class="offset-xl-1 offset-xxl-1 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 my-auto fix-about-image"
            >
              <div
                class="d-flex justify-content-center align-items-center h-100"
              >
                <img
                  src="../assets/images/about/image_2.jpg"
                  alt="Plomeros 911 vision"
                  class="w-100 rounded-4"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-5"
          >
            <h3 class="my-auto text-size-32 fw-bold text-center text-size-32">
              VALORES
            </h3>
          </div>
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10"
          >
            <p class="text-size-26 mt-3 text-center">
              Plomeros 911 es una empresa basada en principios y valores,
              orientados en un esquema de Ganar – Ganar, con nuestra dedicación
              centrada en el cliente, porque entendemos que un buen servicio y
              un cliente satisfecho nos brinda la oportunidad de nuestro propio
              crecimiento.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div class="my-5">
          <div class="row justify-content-center">
            <div
              class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 my-auto"
              v-for="(value, index) in valuesAbout"
              :key="index"
            >
              <div class="animation-scale-in-out">
                <img
                  :src="
                    require('../assets/images/about/image_about_value_' +
                      (index + 1) +
                      '.png')
                  "
                  :alt="'valor imagen ' + index"
                  class="w-50 mx-auto d-block"
                />
                <div class="bg-color_2 text-white p-2 text-center my-4">
                  <p class="fw-bold text-size-24 m-0">{{ value.name }}</p>
                  <p>{{ value.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import MainComponent from "@/components/Main.vue";
export default {
  name: "AboutView",
  components: {
    MainComponent,
  },
};
</script>
