<script setup>
import { defineProps } from "vue";

defineProps({
  sectionName: String,
});
</script>

<template>
  <div>
    <div
      class="main-content"
      :style="{
        'background-image':
          'url(' +
          require('../assets/images/' +
            sectionName +
            '/' +
            sectionName +
            '.jpg') +
          ')',
      }"
    >
      <div
        class="d-flex position-relative h-100 flex-column justify-content-center align-items-center"
      >
        <div class="text-center">
          <img src="../assets/logo_main.png" class="w-20" alt="#" />
        </div>
        <div class="w-15 fix-button-main">
          <a
            href="https://wa.me/message/DBF2PLBXZCMQA1"
            class="btn btn-easy-green d-block px-4 py-2 shadow text-size-20 mt-2 rounded"
            >Contactar ahora</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainComponent",
};
</script>
