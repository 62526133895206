<script setup>
const services = [
  {
    name: "Reparación de Fugas",
  },
  {
    name: "Desatascos y Destape de Tuberías",
  },
  {
    name: "Instalación de Tuberías y Accesorios",
  },
  {
    name: "Reparación de Sistemas Sépticos",
  },
  {
    name: "Mantenimiento Preventivo",
  },
  {
    name: "Reparación y Reemplazo de Calentadores de Agua",
  },
  {
    name: "Instalación de Sistemas de Fontanería",
  },
  {
    name: "Reparación de Grifos y Válvulas",
  },
];

const testimonials = [
  {
    name: "Ana G.",
    message:
      "¡Increíble servicio las 24 horas! Llamé a Plomeros911 en medio de la noche con una fuga urgente, y su equipo respondió rápidamente. No solo repararon el problema de inmediato, sino que también mostraron un profesionalismo excepcional. ¡Altamente recomendados!",
  },
  {
    name: "Carlos M.",
    message:
      "Plomeros911 superó mis expectativas. Necesitaba una instalación de fontanería para mi nuevo negocio y su equipo no solo realizó un trabajo impecable, sino que también se aseguraron de que todo cumpliera con los códigos locales. ¡Confío plenamente en su experiencia!",
  },
  {
    name: "Laura R.",
    message:
      "Lidiar con una obstrucción persistente en mi hogar era frustrante hasta que llamé a Plomeros911. Su plomero fue puntual, educado y resolvió el problema de manera eficiente. ¡Ahora mi sistema de fontanería funciona mejor que nunca!",
  },
  {
    name: "Javier D.",
    message:
      "La atención personalizada de Plomeros911 marcó la diferencia. Desde la primera llamada hasta la finalización del trabajo, me sentí atendido y escuchado. No solo repararon la fuga, sino que también me dieron consejos útiles para prevenir problemas futuros. ¡Impresionado!",
  },
  {
    name: "Marta S.",
    message:
      "Tuve un problema urgente con mi calentador de agua y Plomeros911 fue mi salvación. Llegaron rápidamente, diagnosticaron el problema con precisión y realizaron la reparación de manera eficiente. Ahora tengo agua caliente de nuevo gracias a su excelente servicio.",
  },
];
</script>

<template>
  <div class="home">
    <MainComponent sectionName="home" />

    <div class="container-fluid">
      <section>
        <div class="my-7 fix-presentation">
          <div class="row">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-5"
            >
              <h1 class="text-center fw-bold">Plomeros 911 Tu Solución</h1>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
              <div
                class="d-flex justify-content-center align-items-center h-100"
              >
                <img
                  src="../assets/images/home/image_1.jpg"
                  alt="Plomeros 911 home image 1"
                  class="w-100 rounded-4"
                />
              </div>
            </div>
            <div
              class="offset-xl-1 offset-xxl-1 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4 my-auto"
            >
              <p class="text-size-28 text-center my-4">
                En Plomeros911, nos destacamos por nuestra profesionalidad,
                honestidad y transparencia en cada paso del proceso. Nos
                esforzamos por superar tus expectativas y asegurarnos de que tu
                experiencia con nosotros sea sin complicaciones. Desde proyectos
                residenciales hasta comerciales, estamos comprometidos con la
                calidad y la atención meticulosa a los detalles.
              </p>
            </div>
          </div>

          <div class="row justify-content-center mt-10">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4 my-auto"
            >
              <p class="text-size-28 text-center my-4">
                Nuestro equipo de plomeros experimentados está listo para
                abordar cualquier desafío, desde reparaciones simples hasta
                proyectos más complejos de instalación y mantenimiento.
              </p>
            </div>
            <div
              class="offset-xl-1 offset-xxl-1 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4 my-auto"
            >
              <div
                class="d-flex justify-content-center align-items-center h-100"
              >
                <img
                  src="../assets/images/home/image_2.jpg"
                  alt="Plomeros 911 home image 1"
                  class="w-100 rounded-4"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div class="row bg-blackblue py-5 justify-content-center">
            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
              <img
                src="../assets/images/home/icons/icon_1.png"
                alt="Plomeros 911 icon"
                class="w-75 d-block mx-auto"
              />
              <p class="text-size-24 text-white m-0 text-center py-4">
                Disponibilidad 24/7
              </p>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
              <img
                src="../assets/images/home/icons/icon_2.png"
                alt="Plomeros 911 icon"
                class="w-75 d-block mx-auto"
              />
              <p class="text-size-24 text-white m-0 text-center py-4">
                Experiencia
              </p>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
              <img
                src="../assets/images/home/icons/icon_3.png"
                alt="Plomeros 911 icon"
                class="w-75 d-block mx-auto"
              />
              <p class="text-size-24 text-white m-0 text-center py-4">
                Transparencia y honestidad
              </p>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
              <img
                src="../assets/images/home/icons/icon_4.png"
                alt="Plomeros 911 icon"
                class="w-75 d-block mx-auto"
              />
              <p class="text-size-24 text-white m-0 text-center py-4">
                Respuesta Rápida a Emergencias
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="my-6">
          <div class="row">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
              <h3 class="text-size-32 fw-bold text-center">
                ALGUNOS DE NUESTROS SERVICIOS
              </h3>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3"
              v-for="(service, index) in services"
              :key="index"
            >
              <div class="service-card my-6 mx-auto animation-scale-in-out">
                <img
                  :src="
                    require('../assets/images/home/services/service_' +
                      (index + 1) +
                      '.jpg')
                  "
                  alt="service 1"
                  class="h-100 w-auto mx-auto d-block rounded"
                />
                <p class="text-size-26 fw-bold text-center">
                  {{ service.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div class="row bg-blackblue py-5 justify-content-center">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-5"
            >
              <h3 class="my-auto fw-bold text-center text-size-32 text-white">
                TESTIMONIOS SOBRE NOSOTROS
              </h3>
            </div>
            <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-5 col-xxl-5">
              <carousel :autoplay="3500" :wrap-around="true">
                <slide v-for="(user, index) in testimonials" :key="index">
                  <div class="carousel__item py-4 px-4 bg-white">
                    <div class="d-flex flex-row justify-content-center">
                      <div class="my-auto">
                        <h3 class="my-auto fw-bold text-center text-size-32">
                          {{ user.name }}
                        </h3>
                      </div>
                    </div>
                    <div class="my-4 px-4">
                      <p>
                        {{ user.message }}
                      </p>
                    </div>
                  </div>
                </slide>

                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="my-6">
          <div class="row">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            >
              <h3 class="text-size-32 fw-bold text-center">
                OBSERVA LOS ULTIMOS VIDEOS DE TRABAJOS REALIZADOS
              </h3>
            </div>
          </div>

          <div class="mt-5">
            <div class="row">
              <div
                class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 text-center"
                v-for="i in 4"
                :key="i"
              >
                <button class="btn bg-color_2 p-2 m-2" @click="openVideo(i)">
                  <div class="thumbs-wrapper animation-scale-in-out">
                    <img
                      :src="require(`../assets/media/thumbs/video_${i}.png`)"
                      alt="#"
                      class="w-100 h-100"
                    />
                  </div>
                </button>
                <FsLightbox
                  :toggler="toggleVideo(i)"
                  :sources="[require(`../assets/media/video_${i}.mp4`)]"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import MainComponent from "@/components/Main.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import FsLightbox from "fslightbox-vue/v3";

export default {
  name: "HomeView",
  components: {
    MainComponent,
    Carousel,
    Slide,
    Navigation,
    FsLightbox,
  },
  methods: {
    openVideo(video) {
      if (video == 1) {
        this.video_1 = !this.video_1;
      }
      if (video == 2) {
        this.video_2 = !this.video_2;
      }
      if (video == 3) {
        this.video_3 = !this.video_3;
      }
      if (video == 4) {
        this.video_4 = !this.video_4;
      }
    },
    toggleVideo(video) {
      if (video == 1) {
        return this.video_1;
      }
      if (video == 2) {
        return this.video_2;
      }
      if (video == 3) {
        return this.video_3;
      }
      if (video == 4) {
        return this.video_4;
      }
    },
  },
  data() {
    return {
      video_1: false,
      video_2: false,
      video_3: false,
      video_4: false,
    };
  },
};
</script>
<style scoped>
.carousel__item {
  min-height: 200px;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
