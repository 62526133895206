<template>
  <header>
    <nav class="navbar navbar-expand-lg bg-white shadow">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand w-5 me-auto">
          <img alt="plomeros911 logo" class="w-100" src="../assets/logo.png" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item px-4">
              <router-link
                to="/"
                class="nav-link text-size-20 text-full-dark fw-bold"
                >Inicio</router-link
              >
            </li>
            <li class="nav-item px-3">
              <router-link
                to="/acerca-de-nosotros"
                class="nav-link text-size-20 text-full-dark fw-bold"
                >Acerca de Nosotros</router-link
              >
            </li>
            <li class="nav-item px-3">
              <router-link
                to="/galeria"
                class="nav-link text-size-20 text-full-dark fw-bold"
                >Galeria</router-link
              >
            </li>
            <li class="nav-item px-3">
              <router-link
                to="/oportunidades"
                class="nav-link text-size-20 text-full-dark fw-bold"
                >Oportunidades Laborales</router-link
              >
            </li>
            <li class="nav-item px-3">
              <router-link
                to="/contacto"
                class="nav-link text-size-20 text-full-dark fw-bold"
                >Contacto</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "HeaderComponent",
};
</script>
