import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import GalleryView from "../views/GalleryView.vue";
import OpportunitiesView from "../views/OpportunitiesView.vue";
import ContactView from "../views/ContactsView.vue";
import NotFoundView from "../views/NotFoundView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Plomeros 911 | Inicio",
      description:
        "Descubre soluciones confiables para tus problemas de fontanería en Plomeros911, servicios las 24/7, asegurando atención inmediata y resultados excepcionales.",
      canonical: "https://plomeros911.com",
    },
  },
  {
    path: "/acerca-de-nosotros",
    name: "about",
    component: AboutView,
    meta: {
      title: "Plomeros 911 | Acerca de Nosotros",
      description:
        "En Plomeros911, combinamos experiencia y profesionalismo para brindarte servicios de fontanería de alta calidad.",
      canonical: "https://plomeros911.com/nosotros",
    },
  },
  {
    path: "/galeria",
    name: "gallery",
    component: GalleryView,
    meta: {
      title: "Plomeros 911 | Galeria",
      description:
        "Explora nuestra Galería para visualizar el compromiso y la calidad en cada proyecto de Plomeros911. Capturamos momentos que reflejan nuestro arduo trabajo.",
      canonical: "https://plomeros911.com/galeria",
    },
  },
  {
    path: "/oportunidades",
    name: "opportunities",
    component: OpportunitiesView,
    meta: {
      title: "Plomeros 911 | Oportunidades Laborales",
      description:
        "Únete a nuestro equipo en constante crecimiento. Plomeros911 ofrece oportunidades de trabajo emocionantes para aquellos apasionados por la fontanería.",
      canonical: "https://plomeros911.com/oportunidades",
    },
  },
  {
    path: "/contacto",
    name: "contact",
    component: ContactView,
    meta: {
      title: "Plomeros 911 | Contacto",
      description:
        "Conéctate con Plomeros911. Estamos listos para responder a tus preguntas, programar servicios y proporcionarte la asistencia que necesitas.",
      canonical: "https://plomeros911.com/contacto",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: NotFoundView,
    meta: {
      title: "Plomeros 911 | Error 404",
      description: "",
      canonical: "",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//SET TITLE
router.beforeEach((to, from, next) => {
  const currentURL = window.location.href;

  //META TAGS DEFAULT
  document.title = to.meta.title ? to.meta.title : "Plomeros 911";
  document.documentElement.lang = "es";
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", to.meta.description);
  document
    .querySelector('meta[name="robots"]')
    .setAttribute("content", "index");
  document
    .querySelector('meta[name="copyright"]')
    .setAttribute("content", "Plomeros 911");

  //URL CANONICAL
  if (to.meta.canonical != "") {
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", to.meta.canonical);
  }

  //META TAGS OF FACEBOOK
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", currentURL);

  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", to.meta.title ? to.meta.title : "Plomeros 911");

  document
    .querySelector('meta[property="og:locale"]')
    .setAttribute("content", "es_VE");
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute(
      "content",
      "http://" + window.location.host + require("@/assets/logo.png")
    );
  document
    .querySelector('meta[property="og:type"]')
    .setAttribute("content", "website");
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", to.meta.description);

  //META TAGS OF TWIITER
  document
    .querySelector('meta[property="twitter:card"]')
    .setAttribute("content", "summary_large_image");

  document
    .querySelector('meta[property="twitter:url"]')
    .setAttribute("content", currentURL);

  document
    .querySelector('meta[property="twitter:title"]')
    .setAttribute("content", to.meta.title ? to.meta.title : "Plomeros 911");
  document
    .querySelector('meta[property="twitter:description"]')
    .setAttribute("content", to.meta.description);
  document
    .querySelector('meta[property="twitter:image"]')
    .setAttribute(
      "content",
      "http://" + window.location.host + require("@/assets/logo.png")
    );
  next();
});

export default router;
