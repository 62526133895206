<script setup>
const socialNetworks = [
  {
    name: "instagram",
    url: "https://www.instagram.com/plomeros911/",
    icon: "fab fa-instagram",
  },
  {
    name: "whatsapp",
    url: "https://wa.me/message/DBF2PLBXZCMQA1",
    icon: "fab fa-whatsapp",
  },
  {
    name: "tiktok",
    url: "https://www.tiktok.com/@plomeros911",
    icon: "fab fa-tiktok",
  },
  {
    name: "facebook",
    url: "#",
    icon: "fab fa-facebook",
  },
  {
    name: "youtube",
    url: "https://www.youtube.com/channel/UCQ8l3NI9OieAANaSVrQHkTA",
    icon: "fab fa-youtube",
  },
];
</script>

<template>
  <div>
    <div class="button-floting">
      <input type="checkbox" class="open-items" />
      <i class="fas fa-plus"></i>
      <div class="sub-items">
        <div v-for="(network, index) in socialNetworks" :key="index">
          <a :href="network.url" class="d-block text-center">
            <i :class="network.icon"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ButtonFlotingComponent",
};
</script>
<style scoped>
i.fa-plus {
  transform: rotate(0deg);
  transition: 0.5s;
}
.open-items {
  width: 40px;
  position: absolute;
  height: 40px;
  top: 18px;
  opacity: 0;
  z-index: 1;
}

.open-items:checked ~ .sub-items {
  transform: scale(1);
  transition: 0.5s;
  z-index: 1;
}
.open-items:checked ~ i.fa-plus {
  transform: rotate(45deg);
  transition: 0.5s;
}

.sub-items {
  position: fixed;
  right: 30px;
  bottom: 110px;
  display: flex;
  flex-direction: column;
  transform: scale(0);
  transition: 0.5s;
  z-index: -1;
}
.sub-items div {
  background: #5199b2;
  border-radius: 50px;
  padding: 10px;
  margin: 8px 0;
}
.button-floting {
  display: block;
  position: fixed;
  right: 25px;
  background: #5199b2;
  padding: 15px;
  bottom: 30px;
  border-radius: 50px;
}
.button-floting i {
  font-size: 45px;
  color: #fff;
}

.sub-items i {
  font-size: 35px;
  color: #fff;
}
</style>
