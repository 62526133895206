<template>
  <div class="my-7">
    <div class="row mb-4">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center"
      >
        <h2 class="text-size-80">404</h2>
        <p class="text-size-28">Pagina no encontrada</p>
        <button @click="$router.go(-1)" class="btn btn-easy-green text-size-24">
          Volver
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <img
          :src="require(`../assets/images/404/404.jpg`)"
          alt="#"
          class="w-100 rounded-4 shadow"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotFoundView",
};
</script>
